<template>
  <div class="dark-mode-toggle-wrapper">
    <button @click="toggleDarkMode" class="dark-mode-toggle-button"
      :class="{ 'dark-mode-active': darkModeStatus === 'enabled' }">
      <span class="dark-mode-toggle-slider"></span>
    </button>
    <p class="dark-mode-status-text">Dark mode is {{ darkModeStatus }}</p>
  </div>
</template>

<script>
import axios from "@/utils/http";

export default {
  name: "ToggleDarkMode",
  props: {
    darkMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      darkModeStatus: this.darkMode ? "enabled" : "disabled",
    };
  },
  methods: {
    // Toggle dark mode and update both the UI and server
    async toggleDarkMode() {
      this.darkModeStatus = this.darkModeStatus === "enabled" ? "disabled" : "enabled";
      this.applyDarkMode();
      this.saveDarkModePreference();

      try {
        await axios.post("/user/toggle_dark_mode", {
          dark_mode_enabled: this.darkModeStatus === "enabled",
        });
      } catch (error) {
        console.error("Error toggling dark mode:", error);
      }
    },
    // Apply dark mode styles to the body element
    applyDarkMode() {
      if (this.darkModeStatus === "enabled") {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    },
    // Save dark mode preference to local storage
    saveDarkModePreference() {
      localStorage.setItem("darkMode", this.darkModeStatus === "enabled");
    },
    // Load dark mode preference from local storage or default setting
    loadDarkModePreference() {
      const storedPreference = localStorage.getItem("darkMode");
      if (storedPreference !== null) {
        this.darkModeStatus = storedPreference === "true" ? "enabled" : "disabled";
      }
      this.applyDarkMode();
    },
  },
  watch: {
    darkMode(newVal) {
      this.darkModeStatus = newVal ? "enabled" : "disabled";
      this.applyDarkMode();
    },
  },
  created() {
    this.loadDarkModePreference();
  },
};
</script>

<style>
.dark-mode-toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dark-mode-toggle-button {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dark-mode-active {
  background-color: #3366cc;
}

.dark-mode-toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
}

.dark-mode-active .dark-mode-toggle-slider {
  transform: translateX(30px);
}

.dark-mode-status-text {
  font-size: 16px;
  color: #333;
}

/* Global styles for dark mode */
body.dark-mode {
  background-color: #1a1a1a;
  color: #ffffff;
}
</style>
