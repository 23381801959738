<template>
  <div>
    <nav>
      <div class="navbar" id="navbar">
        <div class="navbar-container">
          <div class="navbar-brand">
            <a href="/">
              <div class="navbar-logo"></div>
              <h2>ML Clever</h2>
            </a>
          </div>

          <NavbarLiMenu v-if="isLoggedInLoaded && !isLoggedIn" />

          <div class="nav-button-container">
            <div class="user-profile" v-if="userProfile">
              <a href="/user/settings">
                <img :src="userProfile.picture" alt="User Logo" class="user-logo" />
              </a>
            </div>
            <div class="hamburger-menu" @click="toggleSidebar">
              <span :class="['menu-line', { 'line1-cross': isSidebarOpen }]"></span>
              <span :class="['menu-line', { 'line2-cross': isSidebarOpen }]"></span>
              <span :class="['menu-line', { 'line3-cross': isSidebarOpen }]"></span>
            </div>
          </div>
        </div>
      </div>

      <div :class="['full-screen-sidebar', { active: isSidebarOpen }]">
        <div class="full-screen-container">
          <div class="sidebar-grid-container">
            <!-- Sidebar Sections for Logged-in Users -->
            <div class="sidebar-grid mobile" v-if="isLoggedInLoaded && isLoggedIn">
              <div v-for="section in sidebarStructure" :key="section.title" class="sidebar-section">
                <div class="sidebar-section-header" @click="toggleSection(section.title)">
                  {{ section.title }}
                </div>
                <div v-if="isSectionOpen(section.title)" class="sidebar-section-content">
                  <div v-for="item in section.items" :key="item.route" class="sidebar-item"
                    @click="navigateTo(item.route)">
                    {{ item.label }}
                  </div>
                </div>
              </div>
              <div class="toggle-options">
                <ToggleDarkMode :darkMode="darkMode" @toggle="handleDarkModeToggle" />
                <ToggleBorderRadius :borderRadiusEnabled="borderRadiusEnabled" @toggle="handleBorderRadiusToggle" />
              </div>
            </div>

            <div class="sidebar-grid mobile" v-else-if="isLoggedInLoaded && !isLoggedIn">
              <div v-for="section in sidebarStructureGuest" :key="section.title" class="sidebar-section">
                <div class="sidebar-section-header" @click="toggleSection(section.title)">
                  {{ section.title }}
                </div>
                <div v-if="isSectionOpen(section.title)" class="sidebar-section-content">
                  <div v-for="item in section.items" :key="item.route" class="sidebar-item"
                    @click="navigateTo(item.route)">
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div>
      <slot></slot>
    </div>

    <footer></footer>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import axios from "@/utils/http";
import ToggleDarkMode from "@/components/user/ToggleDarkMode.vue";
import ToggleBorderRadius from "@/components/user/ToggleBorderRadius.vue";
import NavbarLiMenu from "@/components/basic/navbar/NavbarLiMenu.vue"; // Import the new parent component

const isSidebarOpen = ref(false);
const darkMode = ref(false);
const borderRadiusEnabled = ref(false);
const isLoggedIn = ref(false);
const isLoggedInLoaded = ref(false);
const userProfile = ref(null);
const openSections = ref([]);
const isMobile = ref(false); // New ref to determine if it's a mobile view

const sidebarStructure = [
  {
    title: "Workspace",
    class: "workspace-section",
    items: [
      { route: "/workspace", label: "Workspace" },
      { route: "/projects", label: "Projects" },
    ],
  },
  {
    title: "Data",
    class: "data-section",
    items: [
      { route: "/view-datasets", label: "Datasets" },
      { route: "/view-preprocessed-datasets", label: "Preprocessed Data" },
      { route: "/view-dashboards", label: "Dashboards" },
    ],
  },
  {
    title: "Models",
    class: "models-section",
    items: [
      { route: "/models", label: "Models" },
      { route: "/predictions", label: "Predictions" },
      { route: "/model-deployments", label: "Model Deployment" },
      { route: "/monitoring", label: "Monitoring" },
      { route: "/pipelines", label: "Pipelines" },
    ],
  },
  {
    title: "Support",
    class: "support-section",
    items: [
      { route: "/support", label: "Support" },
      { route: "/learning", label: "Learning" },
    ],
  },
  {
    title: "Account",
    class: "account-section",
    items: [
      { route: "/user/settings", label: "Settings" },
      { route: "/logout", label: "Logout", action: "logoutUser" },
    ],
  },
];

const sidebarStructureGuest = [
  {
    title: "Account",
    items: [
      { route: "/auth/login", label: "Login" },
      { route: "/auth/register", label: "Sign Up" },
    ],
  },
  {
    title: "Platform",
    items: [
      { route: "/platform/auto-ml", label: "AutoML" },
      { route: "/platform/model-training", label: "Model Training" },
      { route: "/platform/custom-pipelines", label: "Custom Pipelines" },
      { route: "/platform/predictions", label: "Predictions" },
      { route: "/platform/batch-predictions", label: "Batch Predictions" },
      { route: "/platform/automated-deployments", label: "Automated Deployments" },
      { route: "/platform/data-preprocessing", label: "Data Preprocessing" },
      { route: "/platform/dashboards", label: "Dashboards" },
      { route: "/platform/api-connections", label: "API Connections" },
      { route: "/platform/dataset-refresh", label: "Dataset Refresh" },
      { route: "/platform/monitoring", label: "Monitoring" },
      { route: "/platform/security", label: "Security" },
      { route: "/platform/enterprise-support", label: "Enterprise Support" },
      { route: "/platform/customer-success", label: "Customer Success" },
      { route: "/platform/integrations", label: "Integrations" },
      { route: "/platform/model-monitoring", label: "Model Monitoring" },
    ],
  },
  {
    title: "Solutions",
    items: [
      {
        label: "Industry",
        sub_items: [
          { route: "/platform/industry/finance", label: "Finance" },
          { route: "/platform/industry/healthcare", label: "Healthcare" },
          { route: "/platform/industry/technology", label: "Technology" },
          { route: "/platform/industry/education", label: "Education" },
          { route: "/platform/industry/retail", label: "Retail" },
        ],
      },
      {
        label: "Stage",
        sub_items: [
          { route: "/platform/stage/enterprise", label: "Enterprise" },
          { route: "/platform/stage/startup", label: "Startup" },
          { route: "/platform/stage/smb", label: "SMB" },
          { route: "/platform/stage/schools", label: "Schools" },
        ],
      },
    ],
  },
  {
    title: "Company Information",
    items: [
      { route: "/about", label: "About Us" },
      { route: "/contact-us", label: "Contact" },
    ],
  },
  {
    title: "Resources",
    items: [
      { route: "/blog", label: "Blog" },
      { route: "/learning", label: "Learning" },
    ],
  },
  {
    title: "Legal & Services",
    items: [
      { route: "/services", label: "Services" },
      { route: "/pricing", label: "Pricing" },
      { route: "/privacy-policy", label: "Privacy Policy" },
      { route: "/terms-of-service", label: "Terms of Service" },
    ],
  },
];

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
  const navbar = document.getElementById("navbar");
  if (isSidebarOpen.value) {
    navbar.classList.add("sidebar-open");
  } else {
    navbar.classList.remove("sidebar-open");
  }
};

const checkIfMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Example threshold for mobile detection
};

const toggleSection = (sectionTitle) => {
  if (isMobile.value) {
    // Mobile behavior: Toggle each section individually
    if (openSections.value.includes(sectionTitle)) {
      openSections.value = openSections.value.filter((title) => title !== sectionTitle);
    } else {
      openSections.value.push(sectionTitle);
    }
  }
  // On desktop, no need to toggle since all sections are always open
};

const isSectionOpen = (sectionTitle) => {
  if (!isMobile.value) {
    return true; // Always open on desktop
  }
  return openSections.value.includes(sectionTitle);
};

const navigateTo = (route) => {
  if (route === "/logout") {
    logoutUser();
  } else {
    window.location.href = route;
    toggleSidebar();
  }
};

// Save preferences to local storage
const savePreferencesToLocal = () => {
  localStorage.setItem("darkMode", JSON.stringify(darkMode.value));
  localStorage.setItem("borderRadiusEnabled", JSON.stringify(borderRadiusEnabled.value));
  if (userProfile.value) {
    localStorage.setItem("userProfile", JSON.stringify(userProfile.value));
  }
};

// Load preferences from local storage
const loadPreferencesFromLocal = () => {
  const darkModeStored = JSON.parse(localStorage.getItem("darkMode"));
  const borderRadiusStored = JSON.parse(localStorage.getItem("borderRadiusEnabled"));
  const userProfileStored = JSON.parse(localStorage.getItem("userProfile"));

  if (darkModeStored !== null) darkMode.value = darkModeStored;
  if (borderRadiusStored !== null) borderRadiusEnabled.value = borderRadiusStored;
  if (userProfileStored) userProfile.value = userProfileStored;

  applyDarkMode();
  applyBorderRadius();
};

// Fetch user preferences from the server and save locally
const getUserPreferences = async () => {
  loadPreferencesFromLocal();

  try {
    const response = await axios.get("/user/preferences");
    if (response.data.success) {
      isLoggedIn.value = true;
      darkMode.value = response.data.dark_mode_enabled;
      borderRadiusEnabled.value = response.data.border_radius_enabled;
      userProfile.value = response.data.user_profile;

      // Save the loaded preferences to local storage
      savePreferencesToLocal();

      applyDarkMode();
      applyBorderRadius();
    } else {
      console.error("Failed to fetch user preferences:", response.data.message);
    }
  } catch (error) {
    console.error("Error fetching user preferences:", error);
  } finally {
    isLoggedInLoaded.value = true;
  }
};

const applyDarkMode = () => {
  if (darkMode.value) {
    document.body.classList.add("dark-mode");
  } else {
    document.body.classList.remove("dark-mode");
  }
};

const applyBorderRadius = () => {
  if (borderRadiusEnabled.value) {
    document.body.classList.add("border-radius");
  } else {
    document.body.classList.remove("border-radius");
  }
};

const handleDarkModeToggle = async () => {
  darkMode.value = !darkMode.value;
  applyDarkMode();
  savePreferencesToLocal();
  try {
    await axios.post("/user/preferences", { dark_mode_enabled: darkMode.value });
  } catch (error) {
    console.error("Error updating dark mode preference:", error);
  }
};

const handleBorderRadiusToggle = async () => {
  borderRadiusEnabled.value = !borderRadiusEnabled.value;
  applyBorderRadius();
  savePreferencesToLocal();
  try {
    await axios.post("/user/preferences", {
      border_radius_enabled: borderRadiusEnabled.value,
    });
  } catch (error) {
    console.error("Error updating border radius preference:", error);
  }
};

const logoutUser = () => {
  // Clear preferences from local storage
  localStorage.removeItem("darkMode");
  localStorage.removeItem("borderRadiusEnabled");
  localStorage.removeItem("userProfile");

  // Redirect to logout route
  window.location.href = "/logout";
};

let lastScrollY = 0;
const scrollBuffer = 20; // Buffer for small scroll fluctuations

const handleScroll = () => {
  const currentScrollY = window.scrollY;
  const navbar = document.getElementById("navbar");

  if (currentScrollY > lastScrollY + scrollBuffer) {
    navbar.classList.add("hidden");
  } else if (currentScrollY < lastScrollY - scrollBuffer || currentScrollY < 50) {
    navbar.classList.remove("hidden");
  }

  if (currentScrollY > 500) {
    navbar.classList.add("navbar-scroll");
  } else {
    navbar.classList.remove("navbar-scroll");
  }

  lastScrollY = currentScrollY;
};

onMounted(() => {
  getUserPreferences();
  checkIfMobile();
  window.addEventListener("resize", checkIfMobile);
  window.addEventListener("scroll", handleScroll);

  if (!isMobile.value) {
    // If it's desktop, open all sections by default
    openSections.value = sidebarStructure.map((section) => section.title);
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", checkIfMobile);
  window.removeEventListener("scroll", handleScroll);
});
</script>
