// Existing getCookie function
export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        const cookieFound = cookies.find(cookie => cookie.startsWith(name + '='));
        if (cookieFound) {
            cookieValue = decodeURIComponent(cookieFound.split('=')[1]);
        }
    }
    return cookieValue;
}
