<template>
    <div class="sub-nav-menu">
      <ul>
        <li><a href="/pipelines">Pipelines</a></li>
        <li><a href="/build_pipeline">Build Pipeline</a></li>
      </ul>
    </div>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  .sub-nav-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px;
    top: 100%; /* Position beneath the navbar */
    left: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  .sub-nav-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .sub-nav-menu li a {
    text-decoration: none;
    color: #333;
    display: block;
    padding: 5px 10px;
  }
  .sub-nav-menu li a:hover {
    background-color: #f0f0f0;
  }
  </style>
  