<template>
  <div class="sidebar-container">
    <div :class="['sidebar', sidebarClass]" @transitionend="onTransitionEnd">
      <ul class="sidebar-nav">
        <li v-for="item in sidebarItems" :key="item.route" @click="navigateTo(item.route)"
          :class="{ 'active-tab': isActiveRoute(item.route) }">
          <img :src="item.icon" alt="" class="sidebar-icon" />
          <transition name="fade-text">
            <span :class="{ 'hidden-text': !textVisible }">{{ item.label }}</span>
          </transition>
        </li>
      </ul>
      <div class="sticky-bottom">
        <!-- Subscription Details Added Here -->
        <div class="subscription-info2">
          <div class="subscription-detail">
            <div class="subscription-detail-text">
              <i class="fas fa-coins info-icon"></i>
              <span v-if="isSidebarOpen && textVisible" class="info-label">Tokens</span>
            </div>
            <span v-if="isSidebarOpen && textVisible" class="info-value">{{
      subscriptionDetails.tokens
    }}</span>
          </div>
          <div class="subscription-detail">
            <div class="subscription-detail-text">
              <i class="fas fa-database info-icon"></i>
              <span v-if="isSidebarOpen && textVisible" class="info-label">Storage</span>
            </div>
            <span v-if="isSidebarOpen && textVisible" class="info-value">{{ subscriptionDetails.storage_gb }} GB</span>
          </div>
        </div>

        <div class="toggle-button" @click="toggleSidebar">
          <i class="fas" :class="isSidebarOpen ? 'fa-chevron-left' : 'fa-chevron-right'"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import axios from "@/utils/http.js";

// Import SVG files as URLs
import WorkspaceIcon from "@/assets/icons/home.svg";
import ProjectsIcon from "@/assets/icons/grid.svg";
import DashboardsIcon from "@/assets/icons/canvas-chart.svg";
import DatasetsIcon from "@/assets/icons/database.svg";
import PreprocessedDatasetsIcon from "@/assets/icons/filter.svg";
import ModelsIcon from "@/assets/icons/chess-knight.svg";
import PredictionsIcon from "@/assets/icons/magic-ball.svg";
import ModelDeploymentIcon from "@/assets/icons/cloud-cube.svg";
import MonitoringIcon from "@/assets/icons/eye-highlight.svg";
import SupportIcon from "@/assets/icons/heart-plus.svg";
import LearningIcon from "@/assets/icons/book-plain.svg";
import PipelinesIcon from "@/assets/icons/timeline.svg";

const isSidebarOpen = ref(true);
const textVisible = ref(true);

const sidebarItems = [
  { route: "/workspace", label: "Workspace", icon: WorkspaceIcon },
  { route: "/workspace", label: "Projects", icon: ProjectsIcon },
  { route: "/view-dashboards", label: "Dashboards", icon: DashboardsIcon },
  { route: "/view-datasets", label: "Datasets", icon: DatasetsIcon },
  {
    route: "/view-preprocessed-datasets",
    label: "Preprocessed Data",
    icon: PreprocessedDatasetsIcon,
  },
  { route: "/models", label: "Models", icon: ModelsIcon },
  { route: "/predictions", label: "Predictions", icon: PredictionsIcon },
  { route: "/model-deployments", label: "Model Deployment", icon: ModelDeploymentIcon },
  { route: "/monitoring", label: "Monitoring", icon: MonitoringIcon },
  { route: "/support", label: "Support", icon: SupportIcon },
  { route: "/learning", label: "Learning", icon: LearningIcon },
  { route: "/pipelines", label: "Pipelines", icon: PipelinesIcon },
];

const subscriptionDetails = ref({ tokens: 0, storage_gb: 0 });

const fetchSubscriptionDetails = async () => {
  try {
    const response = await axios.get("/user/subscription_details");
    subscriptionDetails.value = response.data;
  } catch (error) {
    console.error("Failed to fetch subscription details", error);
  }
};

onMounted(() => {
  const storedSidebarState = localStorage.getItem("isSidebarOpen");
  if (storedSidebarState !== null) {
    isSidebarOpen.value = storedSidebarState === "true";
    textVisible.value = isSidebarOpen.value; // Ensure consistency with `textVisible`
  }

  fetchSubscriptionDetails();
});

const toggleSidebar = () => {
  if (isSidebarOpen.value) {
    textVisible.value = false;
    setTimeout(() => {
      isSidebarOpen.value = false;
      // Save to localStorage
      localStorage.setItem("isSidebarOpen", "false");
    }, 300);
  } else {
    isSidebarOpen.value = true;
    setTimeout(() => {
      textVisible.value = true;
      // Save to localStorage
      localStorage.setItem("isSidebarOpen", "true");
    }, 300);
  }
};

const navigateTo = (route) => {
  window.location.href = route;
};

const isActiveRoute = (route) => {
  return window.location.pathname === route;
};

const sidebarClass = computed(() => (isSidebarOpen.value ? "open" : "closed"));

const onTransitionEnd = (event) => {
  if (event.propertyName === "width" && isSidebarOpen.value) {
    textVisible.value = true;
  }
};

watch(isSidebarOpen, (newVal) => {
  document.querySelector(".main-container").classList.toggle("sidebar-closed", !newVal);
});
</script>

<style scoped>
.main-container {
  display: grid;
  grid-template-columns: 250px 1fr;
  transition: grid-template-columns 0.3s ease;
}

.main-container.sidebar-closed {
  grid-template-columns: 60px 1fr;
  /* Adjust width when sidebar is closed */
}

.sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  padding-top: 70px;
  transition: width 0.3s ease;
  box-shadow: var(--blue-box-shadow);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .main-container {
    display: flex;
    flex-direction: column;
  }

  .sidebar {
    position: relative;
    width: 100%;
  }
}

.sidebar.open {
  width: 250px;
}

.sidebar.closed {
  width: 60px;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
  overflow-y: auto;
  height: calc(100% - 70px);
}

.sidebar-nav li {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 50px;
  transition: all 0.3s ease;
}

.sidebar-nav li:hover {
  color: var(--primary-color);
}

.sidebar-nav li .sidebar-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  transition: margin-right 0.3s ease;
}

.sidebar.closed .sidebar-nav li .sidebar-icon {
  margin-right: 0;
}

.hidden-text {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hidden {
  display: none;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--background-color);
  padding: 10px;
  box-shadow: var(--blue-box-shadow);
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-button {
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  color: var(--primary-color);
}

.content-container {
  padding: 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navbar {
  background-color: var(--background-color);
  box-shadow: var(--blue-box-shadow);
}

.modal-overlay {
  position: fixed;
  top: 0px !important;
  left: 250px !important;
  height: calc(100%) !important;
  width: calc(100vw - 250px) !important;
  background: white;
  z-index: 499;
  overflow-y: auto;
}

.main-container.sidebar-closed .modal-overlay {
  left: 60px !important;
  width: calc(100vw - 60px) !important;
}

.modal-content {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  padding: 20px;
  overflow-y: hidden;
  position: relative;
  background: var(--secondary-bg-color);
  padding-top: 70px !important;
}

.sidebar {
  z-index: 799;
}

@media screen and (max-width: 768px) {
  .modal-overlay {
    width: 100% !important;
    left: 0 !important;
    z-index: 999;
  }

  .sidebar {
    z-index: 299;
  }

  .main-container {
    flex-direction: column-reverse;
  }

  .sidebar.open {
    width: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
  {
  opacity: 0;
}

.fade-text-enter-active {
  transition: opacity 0.3s ease;
}

.fade-text-leave-active {
  transition: opacity 0.1s ease;
}

.fade-text-enter,
.fade-text-leave-to {
  opacity: 0;
}
</style>
