// src/utils/http.js
import axios from "axios";
import { getCookie } from "@/utils/cookies";

// Assuming Flask sets CSRF token in a cookie named 'csrf_token'
const csrfToken = getCookie("csrf_access_token");

if (csrfToken) {
  // Flask typically uses 'X-CSRFToken', but double-check your backend's requirements
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
}

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      // Redirect to /pricing page on 403 error
      alert(
        "Not enough tokens. Please check the pricing page for more details."
      );
      window.location.href = "/pricing";
    }
    return Promise.reject(error);
  }
);

export default axios;
