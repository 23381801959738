<template>
  <div class="border-radius-toggle-wrapper">
    <button @click="toggleBorderRadius" class="border-radius-toggle-button"
      :class="{ 'border-radius-enabled': borderRadiusStatus === 'disabled' }">
      <span class="border-radius-toggle-slider"></span>
    </button>
    <p class="border-radius-status-text">Toggle border radius</p>
  </div>
</template>

<script>
import axios from "@/utils/http";

export default {
  name: "ToggleBorderRadius",
  data() {
    return {
      borderRadiusStatus: "disabled", // Default status
    };
  },
  methods: {
    // Toggle border radius and update both the UI and server
    async toggleBorderRadius() {
      this.borderRadiusStatus =
        this.borderRadiusStatus === "enabled" ? "disabled" : "enabled";
      this.applyBorderRadius();
      this.saveBorderRadiusPreference();

      try {
        await axios.post("/user/toggle_border_radius", {
          border_radius_enabled: this.borderRadiusStatus === "enabled",
        });
      } catch (error) {
        console.error("Error toggling border radius:", error);
      }
    },
    // Apply border radius styles to the body element
    applyBorderRadius() {
      if (this.borderRadiusStatus === "enabled") {
        document.body.classList.add("border-radius");
      } else {
        document.body.classList.remove("border-radius");
      }
    },
    // Save border radius preference to local storage
    saveBorderRadiusPreference() {
      localStorage.setItem("borderRadius", this.borderRadiusStatus === "enabled");
    },
    // Load border radius preference from local storage or set default
    loadBorderRadiusPreference() {
      const storedPreference = localStorage.getItem("borderRadius");
      if (storedPreference !== null) {
        this.borderRadiusStatus = storedPreference === "true" ? "enabled" : "disabled";
      }
      this.applyBorderRadius();
    },
  },
  created() {
    this.loadBorderRadiusPreference();
  },
};
</script>

<style>
.border-radius-toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.border-radius-toggle-button {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.border-radius-enabled {
  background-color: #4caf50;
}

.border-radius-toggle-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
}

.border-radius-enabled .border-radius-toggle-slider {
  transform: translateX(30px);
}

.border-radius-status-text {
  font-size: 16px;
  color: #333;
}

/* Global styles for border radius */
body.border-radius * {
  border-radius: 8px !important;
}
</style>
